const VehicleType = {
  Sedan: "Sedan",
  SUV: "SUV",
  SUV2: "2-Row SUV",
  SUV3: "3-Row SUV",
  Coupe: "Coupe",
  PickupTruck: "Pickup Truck",
  Minivan: "Minivan",
  Other: "Other",
};

const CarWashType = {
  FullCarWash: "Full Car Wash",
  InteriorCarWash: "Interior Only",
  ExteriorCarWash: "Exterior Only",
};

const SpongesServices = {
  CarWash: "Car Wash",
};

exports.VehicleType = VehicleType;
exports.CarWashType = CarWashType;
exports.SpongesServices = SpongesServices;
