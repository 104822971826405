import { useState } from "react";
import InputBox from "../Shared/InputBox";

const Register = ({ handleSignUp }) => {
  const [value, setValue] = useState({});
  const [valid, setValid] = useState(false);

  const handleChange = (e) => {
    let tempValue = { ...value, [e.target.name]: e.target.value };
    setValid(validate(tempValue));
    setValue(tempValue);
  };

  const validate = (data) => {
    if (!data.firstName) return false;
    if (!data.lastName) return false;
    // Simple email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) return false;
    return true;
  };

  return (
    <div>
      <div className="authorization-title">Almost done</div>
      <div className="authorization-desc text-muted">
        Fill in personal data to complete registration.
      </div>
      <InputBox
        caption="First Name"
        name="firstName"
        autofocus={true}
        marginBottom="16px"
        changeHandler={handleChange}
      />
      <InputBox
        caption="Last Name"
        name="lastName"
        marginBottom="16px"
        changeHandler={handleChange}
      />
      <InputBox
        caption="Email"
        name="email"
        marginBottom="16px"
        changeHandler={handleChange}
      />

      <div className="register-note">
        By creating an account, you acknowledge that you have read and agree
        with the <a href="/privacypolicy">Privacy Policy</a> and{" "}
        <a href="/terms">Terms&Conditions</a>
      </div>
      <button
        className={`btn ${!valid && "btn-disabled"} btn-next`}
        onClick={() => handleSignUp(value)}
      >
        Complete
      </button>
      <p style={{ marginLeft: "100%" }}>3/3</p>
    </div>
  );
};

export default Register;
