import { Link } from "react-router-dom";
import Navigation from "../Shared/Navigation";
import "./Order.css";

const Header = ({ isLogged, logout, handleShowAuth, pageName }) => {
  return (
    <header className="header">
      <Navigation
        inHome={false}
        isLogged={isLogged}
        logout={logout}
        handleShowAuth={handleShowAuth}
        user={JSON.parse(localStorage.getItem("user"))}
      />
      <div className="bread-cumb">{pageName}</div>
    </header>
  );
};

export default Header;
