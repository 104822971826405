import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input/input";
import { sendSms } from "../service/fetchService";
import usFlag from "../Shared/assets/img/us-flag.png";
import { Colors } from "../utils/colors";

const SpongesInformationalModal = ({ header, body, show, buttonText, onClose }) => {
  const [value, setValue] = useState();
  const [valid, setValid] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      {body !== undefined && body !== null && <Modal.Body>{body}</Modal.Body>}
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SpongesInformationalModal;
