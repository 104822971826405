import { useHistory } from "react-router-dom";
import "./Navigation.css";
import logo from "./assets/img/logo.png";
import NavItem from "./NavItem";
import navUser from "./assets/img/nav-user.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useState, useEffect } from "react";

const Navigation = ({ inHome, isLogged, logout, handleShowAuth, user }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const page = inHome ? "" : "home";
  const history = useHistory();

  useEffect(() =>
    window.addEventListener("click", (e) => {
      if (e.target.name !== "userButton") setShowUserMenu(false);
    })
  );

  const handleUserClick = () => {
    if (isLogged) {
      setShowUserMenu(true);
    } else {
      handleShowAuth(true);
    }
  };

  return (
    <div className="navigation">
      <div className={`nav-menu ${showDropDown ? "nav-dropdown" : ""}`}>
        <NavItem caption="Services" linkRef={"/#service"} />
        <NavItem caption="How It Works" linkRef={"/#hiw"} />
        <NavItem caption="Download Sponges App" linkRef={"/#mobileapp"} />
        <div className="dropdown-wrapper">
          <div className="navitem">
            <img
              src={navUser}
              alt=""
              name="userButton"
              onClick={handleUserClick}
            />
          </div>
          {showUserMenu && user && (
            <div className="dropdown-menu">
              <div className="dropdown-item">{user.firstName}</div>
              <div
                className="dropdown-item"
                onClick={(e) => {
                  history.push("/orders");
                }}
              >
                Orders
              </div>
              <div
                className="dropdown-item"
                onClick={(e) => {
                  if (logout) {
                    logout();
                  }
                  localStorage.removeItem("user");
                  history.push("/");
                }}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;