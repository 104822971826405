import React from "react";
import { withRouter } from "react-router";
import "../Order.css";
import { Container, Row, Col } from "react-bootstrap";
import star from "../../Shared/assets/img/star.png";
import Header from "../Header";
import moment from "moment";
import { VehicleType } from "../../utils/enums";
import { calculateWasherReviews } from "../../utils/orders";

class OrderDetail extends React.Component {
  state = {
    order: {},
    isLoading: true,
  };

  formatBodyCar = (bodyCar) => {
    let values = {};
    bodyCar.forEach((b) => {
      if (!values[b]) {
        values[b] = 1;
      } else {
        values[b] += 1;
      }
    });
    let elements = [];
    for (let v in values) {
      elements.push(`${VehicleType[v]}: ${values[v]}`);
    }

    return elements.join(", ");
  };

  getWasherPicture = (washer) => {
    return process.env.REACT_APP_SPONGES_BACKEND_API + washer.urlImage;
  };

  render() {
    const { location, serviceName, bodyCar, services, time, washer, cost, note } = this.props.location.state.order;

    return (
      <main className="main-container map-back" style={{ maxHeight: 100 }}>
        <Container>
          <Header
            isLogged={localStorage.getItem("user") ? true : false}
            logout={this.logout}
            handleShowAuth={(value) => this.setState({ showAuth: value })}
            pageName="Order Summary"
          />
          <div
            style={{
              marginLeft: "10%",
            }}
          >
            <Row>
              <Col>
                <div>
                  <h2>Address</h2>

                  <p className="summary-text">{location}</p>
                </div>
                <div>
                  <h2>Date & Time</h2>
                  <p className="summary-text">{moment(time).format("MMMM Do YYYY, h:mm a")}</p>
                </div>

                <div>
                  <h2>Total</h2>
                  <p className="summary-text">${cost.toFixed(2)}</p>
                  <p className="summary-text">
                    *You pay after the order is completed using credit card, cash or zelle.
                  </p>
                </div>
                <div>
                  {Object.keys(services).length > 0 ? <h2>Services</h2> : <div></div>}

                  {Object.keys(services).length > 0 ? (
                    <div>
                      {services.map((s) => (
                        <p key={s.service}>{`${s.service}: $${s["cost"].toFixed(2)}`}</p>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="summary-text">
                  <h2>Car Washer</h2>
                  <Row>
                    <div>
                      <img src={this.getWasherPicture(washer)} style={{ height: 60, width: 45 }} />
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <p style={{ marginBottom: -3 }}>{`${washer.firstName} ${washer.lastName}`}</p>
                        <img src={star} style={{ marginRight: 8 }}></img>
                        {calculateWasherReviews(washer.reviews).toFixed(2)}
                        {`(${washer.reviews.length} reviews)`}
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="summary-text">
                  <h2>Car washer note</h2>
                  <textarea disabled className="car-wash-note " style={{ width: 300, height: 50 }}>
                    {note}
                  </textarea>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </main>
    );
  }
}

export default withRouter(OrderDetail);
