import React from "react";
import { withRouter } from "react-router";
import "../Order.css";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import Header from "../Header";
import { getOrderHistory } from "../../service/fetchService";
import OrderCar from "./OrderCard";
import ModalOrderBooked from "./ModalOrderBooked";
import SpongesLoader from "../../Shared/SpongesLoader";

class OrderHistory extends React.Component {
  state = { orders: [], showModal: false, isLoading: true };

  async componentDidMount() {
    const res = await getOrderHistory();

    if (res.status == 200) {
      const orders = await res.json();
      this.setState({ orders, isLoading: false });

      if (orders.length > 0) {
        let minutes = (new Date() - new Date(orders[orders.length - 1].updatedAt)) / 60000

        if (minutes < 0.25) {
          this.setState({ showModal: true })
        }
      }

    } else {
      alert("Unable to get orders");
    }
  }

  render() {
    const { orders, isLoading } = this.state;
    const { history } = this.props;
    return (
      <main className="main-container map-back" style={{ maxHeight: 100 }}>
        <Container>

          {/* Header */}

          <Header
            isLogged={localStorage.getItem("user") ? true : false}
            logout={this.logout}
            handleShowAuth={(value) => this.setState({ showAuth: value })}
            pageName="Order Details"
          />

          {/* Sponges loader */}

          <SpongesLoader isLoading={isLoading}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {orders.map((o) => (
                <OrderCar
                  style={{ marginRight: 40, marginBottom: 40 }}
                  address={o.location}
                  cost={o.cost}
                  time={o.dateTime}
                  status={o.status}
                  onClickDetails={() =>
                    history.push({
                      pathname: "/order-details",
                      state: { order: o },
                    })
                  }
                />
              ))}
            </div>
          </SpongesLoader>

          {/* Show Order Booked Panel */}

          <ModalOrderBooked
            show={this.state.showModal}
            handleClose={() => this.setState({ showModal: false })}
          />

        </Container>
      </main>
    );
  }
}

export default withRouter(OrderHistory);