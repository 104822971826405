import React from "react";
import { Button } from "react-bootstrap";

const NotFound = () => (
  <div style={{ marginTop: "10%", marginLeft: "30%" }}>
    <h2 style={{ marginLeft: 100 }}>404 page not found</h2>
    <p>We are sorry but the page you are looking for does not exist.</p>
    <Button style={{ height: 50, marginLeft: "22%", marginTop: 40 }}>
      Go to Home Screen
    </Button>
  </div>
);

export default NotFound;
