import { useState, useRef } from "react";
import "./CheckBox.css";

const CheckBox = ({
  name,
  cost,
  defaultValue,
  id,
  changeHandler,
  checkStyle = "check",
  disabled = false,
  subTotal,
  style,
}) => {
  const [checked, setCheked] = useState(defaultValue ? defaultValue : false);

  const checkHandler = (e) => {
    if (subTotal > 0) {
      if (disabled) return;
      const newCheckedValue = !checked;
      setCheked(newCheckedValue);
      if (changeHandler) changeHandler(newCheckedValue, id, cost);
    }
  };

  return (
    <>
      <div className="check-form-group" style={style} data-id={id}>
        <label className="check-label-element" onClick={checkHandler}>
          {`${name} $(${cost})` || "_"}
        </label>
        <div
          className={`circle-check ${
            checked
              ? checkStyle === "check"
                ? "circle-check-cheked"
                : "circle-option-checked"
              : ""
          }`}
          onClick={checkHandler}
        ></div>
      </div>
    </>
  );
};

export default CheckBox;
