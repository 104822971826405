import './Modal.css';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ children, caption, handleClose }) => {
  return (
    <>
      <div className="modal-background" onClick={handleClose}>
        <div className="modal-container" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <div className="modal-caption">{caption}</div>
            <FaTimes className="modal-close" onClick={handleClose} />
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
      )
    </>
  );
};

export default Modal;
