const LogEvents = {
  // Screens
  OnboardingScreen: "OnboardingScreen",
  PhoneNumberScreenKey: "PhoneNumberScreen",
  VerificationCodeScreenKey: "VerificationCodeScreen",
  SignUpScreenKey: "SingUpScreen",
  HomeScreenKey: "HomeScreen",
  SelectVehicleScreenKey: "SelectVehicleScreen",
  OrderySummaryScreenKey: "OrderSummaryScreen",
  OrdersScreenKey: "OrdersScreen",
  PaymentscreenKey: "PaymentScreen",
  RewardsScreenKey: "RewardScreen",
  IdeaScreenKey: "IdeaScreen",
  ShowLoginPageKey: "ShowLogin", // for website

  // Events

  ServicesSelectionEvent: "ServicesSelection", // only website, select services (car wash, pop a lock)
  ErrorEvent: "Error",
  LogoutEvent: "Logout",

  //Login Flow
  ReSendVerificationCodeEvent: "ReSendVerificationCode",
  WrongVerificationCodeEvent: "WrongVerificationCode",
  UnableToSignUpEvent: "UnableToSignUp",

  // Home
  InvalidAddressEvent: "InvalidAddress",
  ShowInAppReviewEvent: "ShowInAppReview",
  UseHistoryAddressEvent: "UseHistoryAddress",

  // Car wash screen
  SelectWaxingEvent: "SelectWaxing",
  UnSelectWaxingEvent: "UnSelectWaxing",
  ScheduleOrderEvent: "ScheduleOrder",
  OnDayChangedEvent: "OnDayChanged",
  NoCarWashAvailable: "NoCarWashAvailable",
  OrderCreationEvent: "OrderCreation",
  ErrorOrderCreationEvent: "ErrorOrderCreation",
  UnableToProvideUtilities: "UnableToProvideUtilites",
  CanProvideUtilities: "CanProvideUtilites",
  OrderTimeChange: "OrderTimeChange",
  SeeMoreOrderSummaryEvent: "SeeMoreOrderSummary",

  // Rewards
  CopyReferralLinkEvent: "CopyReferralLink",

  // Main Flow
  AddCarEvent: "AddCar",
  SubstractCarEvent: "SubstractCar",
  WhatTypeOfServiceWeProvideEvent: "WhatTypeOfServiceWeProvide",
  ChangeCarWashTypeEvent: "ChangeCarWashType",

  // Payment
  PayWithBitcoinEvent: "PayWithBitcoin",

  // Order Details
  TipAndReviewEvent: "TipAndReview",
  ChangeTipAmountEvent: "ChangeTipAmount",

  // Idea
  SendIdeaEvent: "SendIdeaEvent",
};

exports.LogEvents = LogEvents;
