import Modal from "../../Shared/Modal";

const ModalOrderBooked = ({ show, handleClose }) => {
  return (
    <>
      {show && (
        <>
          <Modal
            handleClose={() => {
              handleClose();
            }}
          >
            <h1>Order Placed!</h1>
            <p>
              Your order has been placed successfully. You can better track the order in our app.{" "}
              <a href="https://onelink.to/sponges">Click here to download it.</a>
            </p>

            <p>Thank you :)</p>
          </Modal>
        </>
      )}
    </>
  );
};

export default ModalOrderBooked;
