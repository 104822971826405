import mixpanel from "mixpanel-browser";
const env = process.env.REACT_APP_NODE_ENV;

mixpanel.init(
  env === "production"
    ? "6153fcfa5d917c571f7b31606d419828"
    : "37eada9e4924f5ef48ecdd6a227154b9"
);

let actions = {
  identify: (customer) => {
    mixpanel.people.set("$name", customer.fullName);
    mixpanel.people.set("$email", customer.email);
    mixpanel.identify(customer.phoneNumer);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
