import { useState, useRef, useEffect } from "react";
import "./LocationInput.css";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateLocation, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
    componentRestrictions: { country: "us" },
  });
  autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
  autoComplete.addListener("place_changed", () => handlePlaceSelect(updateLocation));
}

async function handlePlaceSelect(updateLocation) {
  const addressObject = autoComplete.getPlace();

  updateLocation({
    address: addressObject.formatted_address,
    lat: autoComplete.getPlace().geometry.location.lat(),
    lng: autoComplete.getPlace().geometry.location.lng(),
  });
}

const LocationInput = ({ name, caption, width, onLocationChange, valid }) => {
  const [focused, setFocused] = useState(false);
  const [location, setLocation] = useState({});
  const inputRef = useRef();

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`,
      () => {
        handleScriptLoad((newLocation) => {
          setLocation(newLocation);
          onLocationChange(newLocation);
        }, inputRef);
      }
    );
  }, []);
  return (
    <>
      <div className="form-group input-box-active" style={{ width }} onClick={() => inputRef.current.focus()}>
        <label className="label-element">{caption || "_"}</label>
        <input
          name={name}
          type="text"
          className="input-element"
          placeholder={"Enter your address to show prices"}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          ref={inputRef}
        />
      </div>
    </>
  );
};

export default LocationInput;
