import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import applogo from "./assets/img/logo.png";
import fivestars from "./assets/img/fivestars.webp";
import closecross from "./assets/img/closecross.webp";

class InstallAppBanner extends React.Component {
  state = {
    openModal: true,
    closeBanner: false,
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          height: 40,
          backgroundColor: "white",
          margin: 10,
        }}
      >
        <img
          src={closecross}
          style={{ width: 20, height: 20, marginTop: 10 }}
          onClick={this.props.onCloseBanner}
        ></img>

        <img
          src={applogo}
          style={{ width: 40, height: 40, marginLeft: 15 }}
        ></img>
        <div style={{ marginLeft: 10, marginTop: 1 }}>
          <p
            style={{
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Get the best of Sponges
          </p>
          <img
            src={fivestars}
            style={{ width: 100, height: 28, marginTop: -40, marginLeft: -10 }}
          ></img>
        </div>

        <a
          href="https://sponges.page.link/poqq"
          style={{
            textDecoration: "underline",
            marginRight: "1px",
            marginTop: 10,
            position: "absolute",
            right: 20,
            color: "black",
          }}
        >
          Use App
        </a>
      </div>
    );
  }
}

export default InstallAppBanner;
