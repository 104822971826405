import React from "react";
import { Button, Modal } from "react-bootstrap";

class ErrorModal extends React.Component {
  render() {
    const { header, body, show, onClose, buttonText } = this.props;
    return (
      <>
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>{header}</Modal.Title>
          </Modal.Header>
          {body !== undefined && body !== null ? (
            <Modal.Body>{body}</Modal.Body>
          ) : (
            <></>
          )}

          <Modal.Footer>
            <Button variant="primary" onClick={onClose}>
              {buttonText === undefined ||
              buttonText === null ||
              buttonText.length === 0
                ? "Accept"
                : buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ErrorModal;
