import React from "react";
import { withRouter } from "react-router";
import "./DeleteAccount.css";
import { Container, Row, Col, Button } from "react-bootstrap";

class DeleteAccount extends React.Component {
  sendMail = () => {
    const mailtoLink = `mailto:contact@sponges.app?subject=Account Deletion Request&body=I would like to delete my account and all associated data. Please assist me with the next steps.`;
    window.location.href = mailtoLink;
  };

  render() {
    return (
      <main className="main-container" style={{ padding: "50px 0" }}>
        <Container>
          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              textAlign: "center",
            }}
          >
            <Row>
              <Col>
                <h2>Delete My Account</h2>
                <p className="delete-text">
                  We're sorry to see you go. If you're sure you want to delete your account, please note that this
                  action will erase all your data and cannot be undone.
                </p>
                <p className="delete-text">
                  To proceed with the account deletion, please send us a request to our support email:
                </p>
                <Button variant="danger" onClick={this.sendMail}>
                  Send Deletion Request
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </main>
    );
  }
}

export default withRouter(DeleteAccount);
