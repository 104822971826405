import React from "react";
import { withRouter } from "react-router";
import "../Order.css";
import Header from "../Header";
import CheckBox from "../../Shared/CheckBox";
import VehicleType from "../VehicleType";
import { Button, Container, Row, FloatingLabel, Col } from "react-bootstrap";
import { Colors } from "../../utils/colors";
import { getBodyCarTypes, getVehicleImage } from "../../utils/utils";
import SpongesInformationalModal from "../../Shared/SpongesInformationalModal";
import Authorization from "../../Authorization/Authorization";
import CarWashTypeBar from "./local/CarWashTypesBar";
import { CarWashType } from "../../utils/enums";
import { Mixpanel } from "../../utils/mixpanel";
import { LogEvents } from "../../utils/logEvents";

class CarWashOrderMainPage extends React.Component {
  state = {
    services: [],
    extraServices: [],
    vehicleTypes: [],
    vehiclePricesMultiplier: 1.0, // this changes with the car wash type that's selected
    subTotal: 0,
    vehiclesSelected: {},
    selectedExtraServices: { FullCarWash: 0 },
    showServiceHelp: false,
    carWashType: CarWashType.FullCarWash,
    showAuth: false,
    user: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0); // scroll the page to the top

    const { city, error, extraServices, vehicles } = this.props.location.state;
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    this.setState({
      showAuth: false,
      user,
      vehicleTypes: vehicles,
      extraServices,
    });
  }

  handleAuthClose = () => {
    this.setState({
      showAuth: false,
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
    });
  };

  handleAddServiceChange = (checked, id, cost) => {
    let { subTotal, selectedExtraServices, vehiclesSelected } = this.state;
    let carsTotal = getBodyCarTypes(vehiclesSelected).length;

    if (checked) {
      subTotal += cost * carsTotal;
      selectedExtraServices[id] = cost * carsTotal;
    } else {
      subTotal -= cost * carsTotal;
      delete selectedExtraServices[id];
    }

    this.setState({ subTotal });
  };

  onVehicleSelectionChange = async (type, cost, add) => {
    let { vehiclesSelected, selectedExtraServices, subTotal } = this.state;

    if (type in vehiclesSelected) {
      if (add === true) {
        vehiclesSelected[type].cost += cost;
        vehiclesSelected[type].quantity += 1;
        subTotal += cost;
        selectedExtraServices["FullCarWash"] += cost;
      } else {
        vehiclesSelected[type].cost -= cost;
        vehiclesSelected[type].quantity -= 1;
        subTotal -= cost;
        selectedExtraServices["FullCarWash"] -= cost;

        if (vehiclesSelected[type].quantity == 0) {
          delete vehiclesSelected[type];
        }

        if (subTotal < 0) subTotal = 0;
      }
    } else {
      vehiclesSelected[type] = { cost, quantity: 1 };
      selectedExtraServices["FullCarWash"] += cost;
      subTotal += cost;
    }

    this.setState({ subTotal });
  };

  onContinue = () => {
    const {
      user,
      subTotal,

      selectedExtraServices,
      vehiclesSelected,
    } = this.state;
    const { address, lat, lng } = this.props.location.state;

    if (user) {
      if (subTotal > 0) {
        this.props.history.push({
          pathname: "/car-wash/scheduling",
          state: {
            cost: subTotal,
            address: address,
            lat: lat,
            lng: lng,
            extraServices: Object.keys(selectedExtraServices).map((s) => {
              return {
                service: s,
                cost: selectedExtraServices[s],
              };
            }),
            bodyCar: getBodyCarTypes(vehiclesSelected),
          },
        });
      }
    } else {
      Mixpanel.track(LogEvents.ShowLoginPageKey);
      this.setState({ showAuth: true });
    }
  };

  render() {
    const {
      services,
      extraServices,
      vehicleTypes,
      subTotal,
      selectedExtraServices,
      vehiclesSelected,
      showServiceHelp,
      carWashType,
      vehiclePricesMultiplier,
      showAuth,
    } = this.state;
    const { address, lat, lng, whatIsIncluded } = this.props.location.state;
    return (
      <main className="main-container map-back" style={{ maxHeight: 100 }}>
        <Container>
          <Header
            isLogged={localStorage.getItem("user") ? true : false}
            logout={this.logout}
            handleShowAuth={(value) => this.setState({ showAuth: value })}
            pageName="Select Vehicle"
          />
          <SpongesInformationalModal
            header={whatIsIncluded.title}
            body={
              <pre>
                <p style={{ textAlign: "left" }}>{whatIsIncluded.content}</p>
              </pre>
            }
            show={showServiceHelp}
            onClose={() => this.setState({ showServiceHelp: false })}
          />
          <div>
            <Row>
              <Col style={{ paddingRight: 50 }}>
                {/* Vehicle Types*/}
                <div>
                  <div className="group-selector">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="group-label">Vehicle Type</label>
                      <label
                        className="group-label"
                        onClick={() => this.setState({ showServiceHelp: true })}
                        style={{ color: Colors.BlueAppColor, fontSize: "13px" }}
                      >
                        What is included?
                      </label>
                    </div>

                    <CarWashTypeBar
                      carWashType={carWashType}
                      onCarWashTypeChanged={(type) => {
                        const multiplier =
                          type === CarWashType.InteriorCarWash
                            ? 0.75
                            : type === CarWashType.ExteriorCarWash
                            ? 0.7
                            : 1.0;
                        this.setState({
                          carWashType: type,
                          vehiclePricesMultiplier: multiplier,
                        });
                      }}
                    />
                    {vehicleTypes.map((v) => (
                      <VehicleType
                        type={v.type}
                        name={v.longName}
                        imageName={getVehicleImage(v.type)}
                        price={v.cost * vehiclePricesMultiplier}
                        key={v.type}
                        onVehicleSelectionChange={this.onVehicleSelectionChange}
                      />
                    ))}
                  </div>
                </div>
              </Col>
              <Col>
                {/* Extra services*/}
                <Row>
                  <Col lg="12" xs="12" md="12">
                    <div className="group-selector">
                      <label className="group-label">Additional Services You Can Add (Price per car)</label>
                      <p className="text-muted group-description">
                        Check the boxes next to the services you want to add to the main service.
                      </p>
                    </div>
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {extraServices.map((s) => (
                    <CheckBox
                      style={{ marginRight: 10, marginBottom: 10, width: 250 }}
                      name={s.longName}
                      cost={s.cost}
                      id={s.service}
                      key={s.service}
                      changeHandler={this.handleAddServiceChange}
                      subTotal={this.state.subTotal}
                    />
                  ))}
                </div>
                <Col>
                  <Row
                    style={{
                      paddingTop: "100px",
                      paddingLeft: "2%",
                      position: "absolute",
                    }}
                  >
                    <h3>Total: ${this.state.subTotal.toFixed(2)}</h3>
                  </Row>
                </Col>

                <Row style={{ paddingTop: "150px" }}>
                  <Button
                    style={{
                      minHeight: "80px",
                      minWidth: 130,
                      maxWidth: 300,
                      marginBottom: 30,
                      backgroundColor: subTotal == 0 ? Colors.DisableButtonGray : Colors.BlueAppColor,
                    }}
                    onClick={this.onContinue}
                  >
                    Continue
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <Authorization show={showAuth} handleClose={this.handleAuthClose} initialDialog={"phone"} />
      </main>
    );
  }
}

export default withRouter(CarWashOrderMainPage);
