import moment from "moment";
import OptionButton from "../../../Shared/OptionButton";
import "./TimeSelector.css";
import { useState, useEffect } from "react";
import NoAvailability from "./NoAvailability";

const TimeSelector = ({ times, defaultValue, handleTimeChange }) => {
  const [selected, setSelected] = useState(defaultValue);

  const formatDate = (dT) => {
    var localDate = moment(dT).local();
    const am_pm = localDate.hour() >= 12 ? "pm" : "am";
    const hour = localDate.hour() % 12 === 0 ? 12 : localDate.hour() % 12;
    return `${hour}:${(localDate.minute() + "00").substring(0, 2)} ${am_pm}`;
  };

  const handleClick = (date) => {
    setSelected(date);
    handleTimeChange(date);
  };

  const areDateSame = (a, b) => {
    return (
      a.minutes() === b.minutes() &&
      a.hour() === b.hour() &&
      a.date() === b.date() &&
      a.month() === b.month() &&
      a.year() === b.year()
    );
  };

  const getMorningTimes = () => {
    return times.filter((el) => moment(el.begin).hour() < 12);
  };

  const getAfternoonTimes = () => {
    return times.filter((el) => moment(el.begin).hour() >= 12);
  };
  return times.length > 0 ? (
    <div className="time-selector">
      {getMorningTimes().length > 0 && <div className="range">Morning</div>}
      <div className="group">
        {getMorningTimes().map((el, index) => {
          return (
            <OptionButton
              key={index}
              text={formatDate(el.begin)}
              width="112px"
              marginright="100px"
              selected={areDateSame(moment(selected), moment(el.begin))}
              handleClick={(_) => handleClick(el.begin)}
            />
          );
        })}
      </div>
      {getAfternoonTimes().length > 0 && <div className="range">Afternoon</div>}
      <div className="group">
        {getAfternoonTimes().map((el, index) => {
          return (
            <OptionButton
              key={index}
              text={formatDate(el.begin)}
              width="112px"
              marginright="10px"
              selected={areDateSame(moment(selected), moment(el.begin))}
              handleClick={(_) => handleClick(el.begin)}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <NoAvailability />
  );
};

export default TimeSelector;
