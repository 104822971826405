// Dependencies
import React from "react";
import { withRouter } from "react-router";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";

// Local
import "../Order.css";
import Map from "../../Shared/Map";
import star from "../../Shared/assets/img/star.png";
import Header from "../Header";
import { VehicleType } from "../../utils/enums";
import { Colors } from "../../utils/colors";
import { createOrder } from "../../service/fetchService";
import SpongesLoader from "../../Shared/SpongesLoader";
import { calculateWasherReviews } from "../../utils/orders";

class CarWashOrderSummary extends React.Component {
  state = {
    secret: undefined,
    showPaymentModal: false,
    carWasherNote: "",
    isUtilitiesSelected: false,
    canProvideUtilites: undefined,
    isLoading: false,
  };

  // Show cars
  formatBodyCar = (bodyCar) => {
    let values = {};
    bodyCar.forEach((b) => {
      if (!values[b]) {
        values[b] = 1;
      } else {
        values[b] += 1;
      }
    });
    let elements = [];
    for (let v in values) {
      elements.push(`${VehicleType[v]}: ${values[v]}`);
    }

    return elements.join(", ");
  };

  // Get Technician picture
  getWasherPicture = (washer) => {
    return process.env.REACT_APP_SPONGES_BACKEND_API + washer.urlImage;
  };

  // Utilities question
  onUtilitiesSelection = (value) => {
    if (value === "0") {
      this.setState({
        isUtilitiesSelected: true,
        canProvideUtilites: false,
      });
    } else {
      this.setState({ isUtilitiesSelected: true, canProvideUtilites: true });
    }
  };

  // OnPress Book Button
  onCreateOrder = async () => {
    const { time, address, bodyCar, extraServices, geoLocation, cost } = this.props.location.state;
    const { carWasherNote, canProvideUtilites, isLoading } = this.state;

    if (isLoading === true) {
      return;
    }
    this.setState({ isLoading: true });

    const newCarWasherNote = `${carWasherNote} -> ${canProvideUtilites === true ? "Have utilities." : "Don't have utilities."
      }`;

    createOrder({
      requestedTime: time,
      address,
      bodyCar,
      extraServices: extraServices,
      geoLocation,
      cost,
      carWasherNote: newCarWasherNote,
    }).then((res) => {
      this.setState({ isLoading: false });
      if (res.status == 200) {
        res.json().then((data) => {
          this.props.history.push({
            pathname: "/orders",
            state: { order: data },
          });
        });
      } else {
        alert("Error creating order");
      }
    });
  };

  render() {
    const { address, serviceName, bodyCar, extraServices, time, washer, cost, geoLocation } = this.props.location.state;
    const { isUtilitiesSelected, isLoading } = this.state;

    return (
      <main className="main-container map-back" style={{ maxHeight: 100 }}>
        <Container>
          <Header
            isLogged={localStorage.getItem("user") ? true : false}
            logout={this.logout}
            handleShowAuth={(value) => this.setState({ showAuth: value })}
            pageName="Order Summary"
          />

          <SpongesLoader isLoading={isLoading}>
            <div
              style={{
                marginLeft: "10%",
              }}
            >
              <Row>
                {/* Map */}

                <Col>
                  <div className="google-map">
                    <Map geoLocation={geoLocation} />
                  </div>
                </Col>

                {/* Order Information */}

                <Col>
                  <div>
                    <p className="summary-subheader-text">
                      Could you provide the utilities? <a>(water and electricity)</a>
                    </p>
                  </div>
                  <div
                    className="summary-text"
                    style={
                      !isUtilitiesSelected
                        ? {
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: 5,
                          maxWidth: 200,
                        }
                        : {}
                    }
                  >
                    <Form.Check
                      style={{
                        display: "inline-block",
                        marginRight: "10px",
                        width: "80px",
                      }}
                      inline
                      label="Yes"
                      name="group1"
                      type="radio"
                      id={`inline-radio-1`}
                      value={"1"}
                      onChange={(e) => this.onUtilitiesSelection(e.target.value)}
                    />
                    <Form.Check
                      style={{
                        display: "inline-block",
                        width: "80px",
                      }}
                      inline
                      label="No"
                      name="group1"
                      type="radio"
                      value={"0"}
                      id={`inline-radio-2`}
                      onChange={(e) => this.onUtilitiesSelection(e.target.value)}
                    />
                  </div>

                  <div>
                    {/* Address of the order */}

                    <p className="summary-subheader-text">Address</p>
                    <p className="summary-text">{address}</p>

                    {/* Date and Time of the order */}

                    <p className="summary-subheader-text">Date & Time</p>
                    <p className="summary-text">{moment(time).format("MMMM Do YYYY, h:mm a")}</p>

                    {/* Cost of the order */}

                    <p className="summary-subheader-text">Total</p>
                    <p className="summary-text">${cost.toFixed(2)}</p>
                    <p className="summary-text">
                      *You pay after the order is completed using Credit Card, Cash, Venmo or Zelle.
                    </p>

                    {/* Extra Services */}

                    {Object.keys(extraServices).length > 0 ? (
                      <p className="summary-subheader-text">Services</p>
                    ) : (
                      <div></div>
                    )}

                    {Object.keys(extraServices).length > 0 ? (
                      <div>
                        {extraServices.map((s) => (
                          <p className="summary-text" key={s.service}>{`${s.service}: $${s["cost"].toFixed(2)}`}</p>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* Technician profile */}

                  <div className="summary-text">
                    <h2>Car Washer</h2>
                    <Row>
                      <div>
                        <img src={this.getWasherPicture(washer)} style={{ height: 60, width: 45 }} />
                        <div
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                          }}
                        >
                          <p style={{ marginBottom: -3 }}>{`${washer.firstName} ${washer.lastName}`}</p>
                          <img src={star} style={{ marginRight: 8 }}></img>
                          {calculateWasherReviews(washer.reviews).toFixed(2)}
                          {`(${washer.reviews.length} reviews)`}
                        </div>
                      </div>
                    </Row>
                  </div>

                  {/* Note for Technician */}

                  <div className="summary-text">
                    <p className="summary-subheader-text">Note for Technician</p>
                    <textarea
                      className="car-wash-note "
                      name="Note"
                      onChange={(e) => this.setState({ carWasherNote: e.target.value })}
                      style={{ width: 300, height: 50 }}
                    />
                  </div>

                  {/* Book Button */}

                  <Button
                    className="summary-text"
                    style={
                      isUtilitiesSelected
                        ? { height: "50px", alignItems: "center" }
                        : {
                          backgroundColor: Colors.DisableButtonGray,
                          height: "50px",
                          alignItems: "center",
                        }
                    }
                    onClick={this.onCreateOrder}
                  >
                    Book Now & Pay Later
                  </Button>
                </Col>
              </Row>
            </div>
          </SpongesLoader>
        </Container>
      </main>
    );
  }
}

export default withRouter(CarWashOrderSummary);