import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button, Card, Row } from "react-bootstrap";

class OrderCard extends React.Component {
  render() {
    const { address, cost, time, status, style, onClickDetails } = this.props;

    return (
      <Card border="info" style={{ width: "22rem", ...style }}>
        <Card.Body>
          <Card.Subtitle>{address}</Card.Subtitle>

          <div
            style={{
              display: "flex",
              marginTop: 30,
              justifyContent: "space-between",
            }}
          >
            <Card.Text className="mb-2 text-muted">
              {moment(time).format("MMM Do YYYY, h:mm a")}
            </Card.Text>
            <Card.Text
              style={{ color: "green", fontWeight: "bold", marginRight: 20 }}
            >
              ${cost}
            </Card.Text>
          </div>
          <Button
            variant="primary"
            style={{ justifyContent: "center" }}
            onClick={onClickDetails}
          >
            Details
          </Button>
        </Card.Body>
      </Card>
    );
  }
}

OrderCard.propTypes = {
  address: PropTypes.string,
  cost: PropTypes.number,
  time: PropTypes.string,
  status: PropTypes.string,
};

export default OrderCard;
