import { useState } from "react";
import ReactGA from "react-ga";
import Modal from "../Shared/Modal";
import Phone from "./Phone";
import Digits from "./Digits";
import Register from "./Register";
import "./Authorization.css";
import { fetchData, sendVerificationCode, singUp } from "../service/fetchService";
import { Mixpanel } from "../utils/mixpanel";
import ErrorModal from "../Shared/ErrorModal";

const Authorization = ({ show, handleClose }) => {
  const [dialog, setDialog] = useState("phone");
  const [error, setError] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState();

  const handlePhone = async (phoneValue) => {
    setPhoneNumber(phoneValue);
    const result = await sendVerificationCode(phoneValue);
    if (!result) {
      setError("Unable to reach server");
    } else {
      const data = await result.json();
      if (result.status === 200) {
        setError();
        setDialog("digits");

        setVerificationCode(data["code"]);
        setUser(data["user"]);
        setToken(data["token"]);
      } else {
        setError(result.message);
      }
    }
  };

  const handleDigits = async (digitsValue) => {
    if (digitsValue.join("") == verificationCode) {
      setError();
      if (user) {
        ReactGA.event({
          category: "User",
          action: "Login an Account",
          data: {
            user: user,
          },
        });
        authorize(user, token);
      } else {
        setDialog("register");
      }
    } else {
      setError("Incorrect verification code. Try again.");
    }
  };

  const handleSignUp = async (userData) => {
    const result = await singUp({ ...userData, phoneNumber });
    const data = await result.json();
    if (result.status === 200) {
      setError();
      setUser(data["user"]);
      setToken(data["token"]);
      authorize(data["user"], data["token"]);
      ReactGA.event({
        category: "User",
        action: "Created an Account",
        data: {
          user: data["user"],
        },
      });
    } else {
      setError("Unable to create user.");
    }
  };

  const authorize = (user, token) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    Mixpanel.identify(user);
    handleClose();
  };

  return (
    <>
      {show && (
        <>
          <Modal
            caption="Create an Account to Book a Car Wash"
            handleClose={() => {
              setDialog("phone");
              handleClose();
            }}
          >
            {dialog === "phone" ? (
              <Phone handlePhone={handlePhone} />
            ) : dialog === "digits" ? (
              <Digits handleDigits={handleDigits} />
            ) : (
              <Register handleSignUp={handleSignUp} />
            )}
            <small className="authorization-error">{error}</small>
          </Modal>
        </>
      )}
    </>
  );
};

export default Authorization;
