import "./Home.css";

import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Navigation from "../Shared/Navigation";
import SelectBox from "../Shared/SelectBox";
import appleStore from "../Shared/assets/img/applestore.png";
import googlePlay from "../Shared/assets/img/googlestore.png";
import mobileApk from "../Shared/assets/img/apk.png";
import carDrop from "../Shared/assets/img/car-drop.png";
import shoppingCart from "../Shared/assets/img/shopping-cart.png";
import userImg from "../Shared/assets/img/user.png";

import LocationInput from "../Shared/LocationInput";
import { getCarWashPackages } from "../service/fetchService";
import SpongesLoader from "../Shared/SpongesLoader";
import SpongesInformationalModal from "../Shared/SpongesInformationalModal";
import ReactGA from "react-ga";
import { Mixpanel } from "../utils/mixpanel";
import { LogEvents } from "../utils/logEvents";
import ServiceElementComponent from "./components/ServiceElementComponent";
import InstallAppBanner from "../Shared/InstallAppBanner";
import CarWashPackageComponent from "./components/CarWashPackageComponent";
import Authorization from "../Authorization/Authorization";
import { isObjectEmpty } from "../utils/utils";

const PackagesKey = {
  BronzePackage: "Bronze",
  SilverPackage: "Silver",
  GoldPackage: "Gold",
  PlatinumPackage: "Platinum",
  DiamondPackage: "Diamond",
  LightsRestoration: "Lights Restoration",
  PaintBuff: "Paint Buff and Restoration",
  CeramicPaint: "Ceramic Paint Treatment",
  Undercarriage: "Undercarriage Cleaning",
  EngineCleaning: "Engine Cleaning",
  Ozone: "Ozone Treatment",
};

const Home = withRouter((props) => {
  const [formData, setFormData] = useState({});
  const [validation, setValidation] = useState();
  const [showAuth, setShowAuth] = useState(false);
  const [textOtherServiceModal, setTextOtherServiceModal] = useState(false);
  const [showOtherServiceModal, setShowOtherServiceModal] = useState(false);
  const [user, setUser] = useState(false);
  const [location, setLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false);
  const [showAppBanner, setShowAppBanner] = useState(true);
  const [packages, setPackages] = useState({});
  const [selectedPackageId, setSelectedPackageId] = useState(undefined);
  const [showSelectAddressModal, setShowSelectAddressModal] = useState(false);

  const history = useHistory();
  const router = user;

  useEffect(() => {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    setShowAppBanner(window.mobileCheck());
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      Mixpanel.identify(user);
      setUser(user);
    }
  }, []);

  const handleAuthClose = () => {
    setShowAuth(false);
    setUser(JSON.parse(localStorage.getItem("user")));
    setIsLoading(false);

    if (!isObjectEmpty(JSON.parse(localStorage.getItem("user"))) && !isObjectEmpty(location) && selectedPackageId) {
      goToScheduling(selectedPackageId);
    }
  };

  const logout = () => {
    Mixpanel.track(LogEvents.LogoutEvent);
    setUser();
  };

  const getPackages = async (location) => {
    const { lat, lng } = location;
    setIsLoading(true);
    const res = await getCarWashPackages({ lat, lng });

    const data = await res.json();
    setIsLoading(false);
    if (data.isValid) {
      setPackages(data.packages);
    }
  };

  const onSelectPackage = (id) => {
    if (location === undefined || location === null || Object.keys(location).length === 0) {
      setSelectedPackageId(id);
      setShowSelectAddressModal(true);
    } else {
      if (user) {
        goToScheduling(id);
      } else {
        setSelectedPackageId(id);
        Mixpanel.track(LogEvents.ShowLoginPageKey);
        setShowAuth(true);
      }
    }
  };

  const goToScheduling = (packageId) => {
    props.history.push({
      pathname: "/scheduling",
      state: {
        cost: packages[packageId],
        extraServices: [{ service: packageId, cost: packages[packageId], longName: `${packageId} Package` }],
        bodyCar: ["Other"],
        ...location,
      },
    });
  };

  return (
    <>
      {showAppBanner ? <InstallAppBanner onCloseBanner={() => setShowAppBanner(false)} /> : null}
      <main className="main-container home-map">
        <section className="container">
          <Navigation
            page=""
            isLogged={localStorage.getItem("user") ? true : false}
            logout={logout}
            handleShowAuth={setShowAuth}
            user={user}
          />
          <h1 className="welcome">Mobile Car Wash & Auto Detailing</h1>
          <div className="comment">
            Contact us at{" "}
            <a
              style={{
                textDecoration: "underline",
              }}
              href="tel:+15125760837"
            >
              (512) 576-0837
            </a>{" "}
            if you have any questions.
          </div>
          <SpongesInformationalModal
            header={"Please select an address first"}
            buttonText={"Ok"}
            show={showSelectAddressModal}
            onClose={() => setShowSelectAddressModal(false)}
          />

          <SpongesInformationalModal
            header={"Oops, we are not in that area yet."}
            body={
              <>
                <p>
                  Is the address correct: <b>{location.address}</b>.
                </p>
                We are available in Austin, and the surrounding areas.
              </>
            }
            buttonText={"Notify me about the launch"}
            show={showInvalidAddressModal}
            onClose={() => setShowInvalidAddressModal(false)}
          />
          <SpongesInformationalModal
            header={textOtherServiceModal}
            body={<h5>To book enter your phone number</h5>}
            show={showOtherServiceModal}
            onClose={() => setShowOtherServiceModal(false)}
          />

          {/* Location Component */}

          <div className="service-selector">
            <LocationInput
              name="location"
              caption="Location"
              width="376px"
              onLocationChange={(location) => {
                setValidation({
                  location: Object.keys(location).length > 0 ? true : false,
                  service_id: formData.service_id ? true : false,
                });
                setLocation(location);
                getPackages(location);
              }}
              valid={validation ? validation.location : true}
            />
          </div>

          {/* Packages Descriptions */}
          <div style={{ marginLeft: "10%" }}>
            <div className="package-row">
              <div>
                <CarWashPackageComponent
                  id={PackagesKey.BronzePackage}
                  name={"Bronze"}
                  headerElement={["Basic Full Car Wash"]}
                  elementList={[
                    "Interior Vacuum/Includes Trunk",
                    "Exterior Hand Wash",
                    "Interior Wipe Down",
                    "Window Cleaned (Interior and Exterior)",
                    "Tire Dressing and Wheel Cleaning",
                    "Bug Removal",
                    "Air freshener (Optional)",
                  ]}
                  price={packages[PackagesKey.BronzePackage]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  id={PackagesKey.SilverPackage}
                  name={"Silver"}
                  headerElement={"All from Bronze package +"}
                  elementList={[
                    "Deep Interior Vacuum and Detailing",
                    "Protection added to every interior surface(Optional)",
                    "Recommended if hadn't cleaned your interior in a while",
                    "Doesn't include shampoo to carpets and seats",
                  ]}
                  price={packages[PackagesKey.SilverPackage]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                  mostPopular={true}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  id={PackagesKey.GoldPackage}
                  name={"Gold"}
                  headerElement={"All from Bronze package +"}
                  elementList={["Clay Bar Polish", "Hand Polish Wax", "Not includes interior detailing"]}
                  price={packages[PackagesKey.GoldPackage]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  id={PackagesKey.PlatinumPackage}
                  name={"Platinum"}
                  headerElement={"All from Silver package +"}
                  elementList={[
                    "Professional leather cleaning & conditioner",
                    "Shampoo Interior Carpets, Floor Mats & Seats",
                  ]}
                  price={packages[PackagesKey.PlatinumPackage]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  id={PackagesKey.DiamondPackage}
                  name={"Diamond"}
                  headerElement={["Full Detailing (All Previous Packages)"]}
                  elementList={["Includes Clay Bar and Waxing"]}
                  price={packages[PackagesKey.DiamondPackage]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
            </div>
            <div className="extraDirty">*Pet Hair or Extra Dirty is an extra $30 added to any package.</div>
            <div className="extraDirty">
              ** SUV 3-rows or Truck is $10 extra added to any package, except for Diamond package
            </div>
            <div className="package-row">
              <div style={{ fontSize: "20px", marginTop: "24px", fontWeight: "bold" }}>Other services</div>
            </div>
            {/* Secondary choices */}
            <div className="package-row">
              <div>
                <CarWashPackageComponent
                  smallPackage={true}
                  id={PackagesKey.LightsRestoration}
                  name={"Light"}
                  elementList={["Lights Restoration"]}
                  price={packages[PackagesKey.LightsRestoration]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  smallPackage={true}
                  id={PackagesKey.PaintBuff}
                  name={"Paint Buff/Restoration"}
                  headerElement={["Price per panel"]}
                  elementList={["Remove Sap tree marks", "Chips", "Superficial scratches"]}
                  price={packages[PackagesKey.PaintBuff]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  smallPackage={true}
                  id={PackagesKey.CeramicPaint}
                  name={"Ceramic"}
                  elementList={["Shining and Protection"]}
                  price={packages[PackagesKey.CeramicPaint]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  smallPackage={true}
                  id={PackagesKey.Undercarriage}
                  name={"Undercarriage"}
                  elementList={["Pressure washer", "Degreaser and shine"]}
                  price={packages[PackagesKey.Undercarriage]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  smallPackage={true}
                  id={PackagesKey.EngineCleaning}
                  name={"Engine Cleaning"}
                  elementList={["Pressure washer", "Degreaser and shine"]}
                  price={packages[PackagesKey.EngineCleaning]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
              <div>
                <CarWashPackageComponent
                  smallPackage={true}
                  id={PackagesKey.Ozone}
                  name={"Ozone"}
                  elementList={["Remove odors"]}
                  price={packages[PackagesKey.Ozone]}
                  color={"#1254A3"}
                  isLoading={isLoading}
                  onClick={onSelectPackage}
                />
              </div>
            </div>
          </div>
        </section>

        {/* How it works sections */}

        <section className="how-it-works" id="hiw">
          <section className="container">
            <h1 className="section-title">How It Works</h1>
            <h6>
              With Sponges you can book a Mobile Car Wash or Auto Detailing Service anywhere/anytime.
              <br /> <br /> We come to your Home, Office or any other place. If you have more questions please give us a
              call at (512) 576-0837
            </h6>
            <div className="options">
              <article>
                <img src={carDrop} alt="" />
                <p>Select the Package you want and location</p>
              </article>
              <article>
                <img src={shoppingCart} alt="" />
                <p>Select the Date & Time.</p>
              </article>
              <article>
                <img src={userImg} alt="" />
                <p>Press the Book Button. Our mobile technicians will go to you.</p>
              </article>
            </div>
          </section>
        </section>

        {/* App Section */}

        <section className="mobile" id="mobileapp">
          <section className="container">
            <div className="wrapper">
              <div className="left">
                <h1 className="title">Sponges App</h1>

                <p className="description">
                  Download our app to create and track your car wash & detailing services from any place.
                  <br /> <br /> Currently, we are serving Austin, and the surrounding areas.
                </p>

                <div className="stores-wrapper">
                  <a href="https://apps.apple.com/us/app/sponges/id1543960772">
                    <img src={appleStore} alt="" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.SpongesTechnologies.Sponges">
                    <img src={googlePlay} alt="" />
                  </a>
                </div>
              </div>
              <div className="right">
                <img src={mobileApk} alt="" />
              </div>
            </div>
          </section>
        </section>

        {/* Show Auth Component */}

        <Authorization show={showAuth} handleClose={handleAuthClose} initialDialog={"phone"} />
      </main>
    </>
  );
});

export default withRouter(Home);
