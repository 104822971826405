import { useState } from "react";
import NumericUpDown from "../Shared/NumericUpDown";
import "./VehicleType.css";

const VehicleType = ({ type, name, imageName, price, onVehicleSelectionChange }) => {
  const [qty, setQty] = useState(0);
  const [ammount, setAmmount] = useState(0);
  const [focused, setFocused] = useState(false);

  const handleQtyChange = (e) => {
    setQty(qty + e);
    setAmmount((qty + e) * price);
    onVehicleSelectionChange(type, price, e === 1);
  };

  return (
    <div className={`vehicle-type-wrapper ${focused ? "vehicle-type-wrapper-active" : ""}`} data-groupname={name}>
      <div className="vehicle-type-column column-car" data-groupname={name}>
        <img
          src={require("../Shared/assets/img/" + imageName).default}
          style={{ height: 43, width: 67 }}
          alt=""
          data-groupname={name}
        />
        <div className="vehicle-type-name" data-groupname={name}>
          {name}
        </div>
      </div>
      <div className="vehicle-type-column column-ammount" data-groupname={name}>
        <NumericUpDown name={name} handleChange={handleQtyChange} setCarcaseFocus={setFocused} />
      </div>
      <div className="vehicle-type-column column-price" data-groupname={name}>
        ${price.toFixed(2)}
      </div>
    </div>
  );
};

export default VehicleType;
