import React from "react";
import PropTypes from "prop-types";
import "../Home.css";

class ServiceElementComponent extends React.Component {
  render() {
    const { text, imgSrc, comingSoon, onClick } = this.props;

    return (
      <a onClick={onClick}>
        <figure>
          <img src={imgSrc} className="service-img" alt="" />
          <figcaption>
            {text}{" "}
            {comingSoon === true ? (
              <p style={{ fontSize: 12 }}>(Coming Soon)</p>
            ) : (
              <></>
            )}
          </figcaption>
        </figure>
      </a>
    );
  }
}

ServiceElementComponent.propTypes = {
  text: PropTypes.string,
  imgSrc: PropTypes.string,
};

export default ServiceElementComponent;
