const NoAvailability = ({}) => {
  return (
    <div style={{ paddingLeft: "20%", paddingTop: "20%" }}>
      <h1 style={{ fontWeight: "bold" }}>No availability</h1>
      <h6>Please select another day</h6>
    </div>
  );
};

export default NoAvailability;
