import React from "react";
import { useState, useEffect } from "react";

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

const Digits = ({ handleDigits }) => {
  const [values, setValues] = useState(["", "", "", ""]);
  const [valid, setValid] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [iRefs, setIRefs] = useState([React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

  useEffect(() => {
    iRefs[0].current.focus();
  }, []);

  const canContinue = () => {
    return values[0] !== "" && values[1] !== "" && values[2] !== "" && values[3] !== "";
  };

  const handleChange = (value, index) => {
    let tempValue = values;
    tempValue[index] = value;
    setValues(tempValue);

    setValid(canContinue());
    setCurrentIndex((index + 1) % 4);
    iRefs[(index + 1) % 4].current.focus();
  };

  const onKeyDown = (e) => {
    const index = parseInt(e.target.name);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = iRefs[prevIndex];
    const next = iRefs[nextIndex];

    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        let vals = values;
        if (values[index]) {
          vals[index] = "";
        }
        if (currentIndex > 0) {
          prev.current.focus();
          setCurrentIndex(currentIndex - 1);
        } else {
          setCurrentIndex(-1);
        }

        setValues(vals);
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
          setCurrentIndex(currentIndex - 1);
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        setCurrentIndex(currentIndex + 1);
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        const key = e.key;
        if (key >= "0" && key <= "9") {
          handleChange(key, parseInt(e.target.name));
        }
        break;
    }
  };
  return (
    <div>
      <div className="authorization-title">4-digit Code</div>
      <div className="authorization-desc text-muted">Enter the code we’ve sent to your phone number.</div>
      <div className="phone-number-wrapper">
        {[0, 1, 2, 3].map((value, index) => {
          return (
            <input
              type="tel"
              min="0"
              max="9"
              pattern="^[0-9]$"
              key={`${index}`}
              id={index}
              name={index}
              value={values[index]}
              className={`digit-input ${index === currentIndex && "digit-input-active"}`}
              maxLength="1"
              autoFocus={index === currentIndex}
              onFocus={(_) => setCurrentIndex(index)}
              ref={iRefs[index]}
              onKeyDown={onKeyDown}
            />
          );
        })}
      </div>
      <button
        className={`btn ${!valid && "btn-disabled"} btn-next`}
        onClick={() => {
          if (canContinue()) handleDigits(values);
        }}
      >
        Next
      </button>
      <p style={{ marginLeft: "100%" }}>2/3</p>
    </div>
  );
};

export default Digits;
