// Dependencies
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

// Local Imports
import MapStyle from './MapStyle';
import userImg from "../Shared/assets/img/pin.png";
import "../Order/Order.css";


const AnyReactComponent = () => <div><img src={userImg} style={{ width: '30px', height: '30px' }} alt="" /></div>;

const Map = ({ geoLocation }) => {

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}` }}
            defaultCenter={{ lat: geoLocation[0], lng: geoLocation[1] }}
            defaultZoom={10}
            defaultOptions={{ styles: MapStyle }}
        >
            <AnyReactComponent
                lat={geoLocation[0]}
                lng={geoLocation[1]}
            />
        </GoogleMapReact>
    );
}

export default Map;