import React from "react";
import PropTypes from "prop-types";
import "../Home.css";
import { Button } from "react-bootstrap";
import SpongesLoader from "../../Shared/SpongesLoader";

class CarWashPackageComponent extends React.Component {
  state = {
    isLoading: this.props.isLoading,
    price: this.props.price,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        price: this.props.price,
        isLoading: this.props.isLoading,
      });
    }
  }

  render() {
    const { id, name, headerElement, elementList, color, onClick, mostPopular, smallPackage } = this.props;
    const { isLoading, price } = this.state;

    return (
      <SpongesLoader isLoading={isLoading}>
        <div className={smallPackage ? "smallPackage" : "package"}>
          {mostPopular && <div className="package-most-popular">Most popular</div>}
          <div className="package-content">
            {name && (
              <div className="package-title">
                <h5>{name}</h5>
                <a className="package-title-price">${price === undefined ? "--" : price}</a>
              </div>
            )}
            <ul className="package-content-details">
              {headerElement && <li style={{ fontFamily: "bold", fontSize: 14, marginBottom: 10 }}>{headerElement}</li>}
              {elementList.map((element, index) => {
                return <li key={index}>{element}</li>;
              })}
            </ul>
          </div>
          <Button className="package-button" style={{ backgroundColor: color }} onClick={() => onClick(id)}>
            Select
          </Button>
        </div>
      </SpongesLoader>
    );
  }
}

CarWashPackageComponent.propTypes = {
  name: PropTypes.string,
  elementList: PropTypes.array,
  price: PropTypes.number,
  onClick: PropTypes.func,
};

export default CarWashPackageComponent;
