import usFlag from "../Shared/assets/img/us-flag.png";
import PhoneInput from "react-phone-number-input/input";
import { useState, useEffect } from "react";

const Phone = ({ handlePhone }) => {
  const [value, setValue] = useState();
  const [valid, setValid] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValid(value && value.length === 12);
  }, [value]);

  return (
    <div>
      <div className="authorization-title">Phone number</div>
      <div className="authorization-desc text-muted">
        Enter your phone number. We will send a 4 digit code for authorization
      </div>
      <div className="phone-number-wrapper">
        <div className="phone-country">
          <img src={usFlag} className="phone-flag" />
          +1
        </div>
        <PhoneInput
          country="US"
          name="phone"
          value={value}
          onChange={setValue}
          className={`phone-input ${focused && "digit-input-active"}`}
          placeholder="(555) 555-5555"
          maxLength="14"
          autoFocus
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
      <button
        className={`btn ${!valid && "btn-disabled"} btn-next`}
        onClick={() => handlePhone(value)}
      >
        Next
      </button>
      <p style={{ marginLeft: "100%" }}>1/3</p>
    </div>
  );
};

export default Phone;
