import React from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

class SpongesLoader extends React.Component {
  state = {
    isLoading: this.props.isLoading,
    children: this.props.children,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        isLoading: this.props.isLoading,
        children: this.props.children,
      });
    }
  }

  render() {
    const { isLoading } = this.state;
    const { children } = this.props;
    return (
      <>
        <div style={{ zIndex: 999, position: "absolute", top: "30%", left: "50%" }}>
          <ClipLoader color="#ffffff" loading={isLoading} css={override} size={150}></ClipLoader>
        </div>
        <div style={isLoading ? { pointerEvents: "none", cursor: "wait" } : {}}>{children}</div>
      </>
    );
  }
}

export default SpongesLoader;
