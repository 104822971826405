import "./DayButton.css";

const DayButton = ({
  weekDay,
  monthDay,
  fullDate,
  selected,
  handleClick,
  disabled,
}) => {
  return (
    <div>
      <button
        className={`day-button ${selected ? "day-selected" : ""} ${
          disabled ? "day-button-disabled" : ""
        } `}
        onClick={() => handleClick(fullDate, disabled)}
        data-date={fullDate}
      >
        <div className="week-day">{weekDay}</div>
        <div className="month-day">{monthDay}</div>
      </button>
    </div>
  );
};

export default DayButton;
