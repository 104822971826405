import React from "react";
import PropTypes from "prop-types";
import "../../Order.css";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { Colors } from "../../../utils/colors";
import { CarWashType } from "../../../utils/enums";

const SelectedButtonStyle = {
  backgroundColor: Colors.White,
  boxShadow: "1px 3px 1px #9E9E9E",
};

const NonSelectedButtonStyle = {
  backgroundColor: Colors.BlueGray,
};

class CarWashTypeBar extends React.Component {
  state = {
    carWashType: this.props.carWashType, // 0 first button, 1 second, 2 third
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        carWashType: this.props.carWashType,
      });
    }
  }

  render() {
    const { carWashType } = this.state;
    const { onCarWashTypeChanged } = this.props;

    return (
      <div
        style={{
          display: "flex",
          backgroundColor: Colors.BlueGray,
          height: 50,
          marginBottom: 20,
          borderRadius: 10,
          justifyContent: "space-evenly",
          minWidth: "300px",
        }}
      >
        <Button
          variant="light"
          className="button-car-wash-type"
          style={carWashType === CarWashType.FullCarWash ? SelectedButtonStyle : NonSelectedButtonStyle}
          onClick={() => {
            onCarWashTypeChanged(CarWashType.FullCarWash);
          }}
        >
          Full Car Wash
        </Button>
        <Button
          variant="light"
          className="button-car-wash-type"
          style={carWashType === CarWashType.InteriorCarWash ? SelectedButtonStyle : NonSelectedButtonStyle}
          onClick={() => {
            onCarWashTypeChanged(CarWashType.InteriorCarWash);
          }}
        >
          Interior Only
        </Button>
        <Button
          variant="light"
          className="button-car-wash-type"
          style={carWashType === CarWashType.ExteriorCarWash ? SelectedButtonStyle : NonSelectedButtonStyle}
          onClick={() => {
            onCarWashTypeChanged(CarWashType.ExteriorCarWash);
          }}
        >
          Exterior Only
        </Button>
      </div>
    );
  }
}

CarWashTypeBar.propTypes = {
  carWashType: PropTypes.string,
  onCarWashTypeChanged: PropTypes.func,
};

export default CarWashTypeBar;
