import React from "react";
import "../../Order.css";
import moment from "moment";
import Header from "../../Header";
import { Button, Row, FloatingLabel, Col } from "react-bootstrap";
import { scheduleOrder } from "../../../service/fetchService";
import DaySelector from "./DaySelector";
import TimeSelector from "./TimeSelector";
import { withRouter } from "react-router";
import { Colors } from "../../../utils/colors";
import SpongesLoader from "../../../Shared/SpongesLoader";
import SpongesInformationalModal from "../../../Shared/SpongesInformationalModal";
import Authorization from "../../../Authorization/Authorization";

class ScheduleTimePage extends React.Component {
  state = {
    user: JSON.parse(localStorage.getItem("user")),
    intervals: [],
    selectedIntervals: [],
    selectedDate: moment(-8640000000000000),
    order: undefined,
    isLoading: true,
    noCarWasherIsAvailable: false,
    showAuth: false,
  };

  async componentDidMount() {
    this.getSchedule();
  }

  getSchedule = async () => {
    const { address, lat, lng, bodyCar, extraServices } = this.props.location.state;

    const result = await scheduleOrder(address, [lat, lng], bodyCar, undefined, extraServices);
    const { intervals, order } = await result.json();
    this.setState({ isLoading: false });
    //Unauthorized
    if (result.status === 401) {
      this.setState({ showAuth: true });
    } else {
      if (!intervals || intervals.length === 0) {
        this.setState({ noCarWasherIsAvailable: true });
      } else {
        const firstDate = intervals[0].begin;
        this.setState({
          intervals,
          order,
          selectedIntervals: intervals.filter((i) => moment(i.begin).date() === moment(firstDate).date()),
        });
      }
    }
  };

  handleAuthClose = () => {
    this.getSchedule().then(() => {
      this.setState({
        showAuth: false,
        user: JSON.parse(localStorage.getItem("user")),
        loading: false,
      });
    });
  };

  onDayChange = (day) => {
    const { intervals } = this.state;
    this.setState({
      selectedIntervals: intervals.filter((interval) => moment(interval.begin).date() === moment(day).date()),
    });
  };

  onTimeChange = async (dateTime) => {
    const { address, lat, lng, bodyCar, extraServices } = this.props.location.state;
    this.setState({ isLoading: true });
    const result = await scheduleOrder(address, [lat, lng], bodyCar, dateTime, extraServices);

    const { intervals, order } = await result.json();
    this.setState({
      selectedDate: order.dateTime,
      intervals,
      isLoading: false,
    });
  };

  render() {
    const { address, bodyCar, extraServices, lat, lng, cost } = this.props.location.state;
    const {
      selectedDate,
      intervals,
      selectedIntervals,
      order,
      isLoading,
      noCarWasherIsAvailable,
      showAuth,
    } = this.state;
    return (
      <main className="main-container map-back">
        <section className="container">
          <Header
            isLogged={localStorage.getItem("user") ? true : false}
            logout={this.logout}
            handleShowAuth={(value) => this.setState({ showAuth: value })}
            pageName="Select date & time"
          />
          <SpongesInformationalModal
            header="No Washer is available right now."
            show={noCarWasherIsAvailable}
            onClose={() => this.setState({ noCarWasherIsAvailable: false })}
          />
          <SpongesLoader isLoading={isLoading}>
            <Button
              variant="primary"
              style={{
                height: "40px",
                float: "right",

                backgroundColor:
                  new moment(this.state.selectedDate) < moment() ? Colors.DisableButtonGray : Colors.BlueAppColor,
              }}
              onClick={() => {
                if (moment(this.state.selectedDate) > moment()) {
                  this.props.history.push({
                    pathname: "/car-wash/summary",
                    state: {
                      serviceName: "Car Wash",
                      address,
                      time: selectedDate,
                      bodyCar: bodyCar,
                      extraServices: extraServices,
                      geoLocation: [lat, lng],
                      washer: order.washer,
                      cost: cost,
                    },
                  });
                }
              }}
            >
              Continue
            </Button>
            <div
              className={localStorage.getItem("user") ? "" : "calendar-disabled"}
              style={{
                alignItems: "center",
              }}
            >
              <DaySelector
                handleChange={this.onDayChange}
                startDate={intervals.length > 0 ? intervals[0].begin : undefined}
                selectedDate={selectedDate}
              />
              <TimeSelector
                times={selectedIntervals}
                defaultValue={selectedDate}
                handleTimeChange={this.onTimeChange}
              />
            </div>
          </SpongesLoader>
        </section>
        <Authorization show={showAuth} handleClose={this.handleAuthClose} initialDialog={"phone"} />
      </main>
    );
  }
}

export default withRouter(ScheduleTimePage);
