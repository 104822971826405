import { useState, useRef } from 'react';
import './InputBox.css';

const InputBox = ({
  caption,
  name,
  placeholder,
  defaultValue,
  width,
  marginBottom,
  noBorder,
  changeHandler,
  autofocus,
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();

  return (
    <>
      <div
        className={`input-form-group ${focused ? 'input-box-active' : ''} ${
          noBorder ? 'noborder' : ''
        }`}
        style={{ width, marginBottom }}
        onClick={() => inputRef.current.focus()}
      >
        <label className="label-element">{caption || '_'}</label>
        <input
          type="text"
          name={name}
          className="input-element"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={changeHandler}
          ref={inputRef}
          autoFocus={autofocus}
        />
      </div>
    </>
  );
};

export default InputBox;
