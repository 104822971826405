import moment from "moment";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import DayButton from "./DayButton";
import "./DaySelector.css";
import { useState, useEffect } from "react";

const DaySelector = ({ handleChange, startDate, selectedDate }) => {
  const [selected, setSelected] = useState(moment());
  const [months, setMonths] = useState();

  const handleClick = (fullDate, disabled) => {
    if (!disabled) {
      const sel = moment(fullDate);
      setSelected(sel);
      handleChange(sel);
    }
  };

  /**
   * Create an array with the days of the week starting on monday
   * @param {date} current day inside week
   * @returns {array} dates array
   */
  const dates = (current) => {
    let week = [];
    if (!current) {
      return week;
    }

    for (var i = 0; i < 7; i++) {
      week.push(moment(current).local().add(i, "d"));
    }
    return week;
  };

  const [currentWeek, setCurrentWeek] = useState(dates(startDate));

  // Sets the month or months for the week
  useEffect(() => {
    const months = [];
    currentWeek.forEach((el) => {
      const month = el.local().format("MMMM");
      if (!months.includes(month)) {
        months.push(month);
      }
    });
    setMonths(months.join("/"));
  }, [currentWeek]);

  useEffect(() => {
    setCurrentWeek(dates(startDate));
    setSelected(moment(startDate));
  }, [startDate]);

  useEffect(() => {
    setSelected(moment(selectedDate));
  }, [selectedDate]);

  /**
   * Changes current week to next or previous week
   * @param {int} direction
   */
  const changeWeek = (direction = 1) => {
    const actual = currentWeek[0];
    actual.set("date", actual.date() + 7 * direction);
    setCurrentWeek(dates(actual));
  };

  const dayNames = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  return (
    <div className="day-selector">
      <div className="week">
        <div className="week-button" onClick={() => changeWeek(-1)}>
          <FaArrowLeft />
        </div>
        <div className="month">{months}</div>
        <div className="week-button" onClick={() => changeWeek()}>
          <FaArrowRight />
        </div>
      </div>
      <div className="days">
        {currentWeek &&
          currentWeek.map((el) => {
            return (
              <DayButton
                weekDay={dayNames[el.day()]}
                monthDay={el.date()}
                key={el}
                fullDate={el}
                handleClick={handleClick}
                selected={el.local().day() === selected.local().day()}
                disabled={el < moment().set("hour", 0)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DaySelector;
