import "./OptionButton.css";

const OptionButton = ({ text, selected, width, marginright, handleClick }) => {
  return (
    <div>
      <button
        className={`option-button ${selected ? "selected" : ""} `}
        style={{ width, marginRight: marginright }}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  );
};

export default OptionButton;
