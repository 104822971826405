import { useState, useRef, useEffect } from "react";
import "./NumericUpDown.css";

const NumericUpDown = ({
  name,
  defaultValue,
  handleChange,
  setCarcaseFocus,
}) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue || 0);
  const refInput = useRef();

  useEffect(() =>
    window.addEventListener("click", (e) => {
      if (e.target.dataset.groupname !== name) {
        setFocused(false);
        setCarcaseFocus(false);
      } else {
        setFocused(true);
        setCarcaseFocus(true);
        //if (refInput.current) refInput.current.focus();
      }
    })
  );

  const validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const handleKeyPress = (e) => {
    if (!validKeys.includes(e.key)) e.preventDefault();
  };

  const handleSignClick = (sign) => {
    if (value + sign >= 0) {
      setValue(value + sign);
      handleChange(sign);
    }
  };

  const handleType = (e) => {
    setValue(parseInt(e.target.value));
    handleChange(e.target.value);
  };

  return (
    <div className="updown-wrapper" data-groupname={name}>
      {focused && (
        <div
          className="down"
          onClick={() => handleSignClick(-1)}
          data-groupname={name}
        ></div>
      )}
      <input
        className={`updown-input ${focused ? "updown-active" : ""}`}
        type="text"
        name={name}
        data-groupname={name}
        onChange={handleType}
        onKeyPress={handleKeyPress}
        ref={refInput}
        value={value || 0}
        maxLength={3}
        disabled={true}
      ></input>
      {focused && (
        <div
          className="up"
          data-groupname={name}
          onClick={() => handleSignClick(+1)}
        ></div>
      )}
    </div>
  );
};

export default NumericUpDown;
