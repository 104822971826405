import { Link } from 'react-router-dom';
import './NavItem.css';

const NavItem = ({ caption, image, linkRef }) => (
  <a href={linkRef} className="navitem" style={{ cursor: 'pointer' }}>
    {image && <img src={image} alt="icon" />}
    {caption}
  </a>
);

export default NavItem;
