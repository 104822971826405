import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home/Home";
import DeleteAccount from "./Home/Delete";
import NotFound from "./NotFound";
import CarWashOrderMainPage from "./Order/CarWash/CarWashOrderMainPage";
import CarWashOrderSummary from "./Order/CarWash/CarWashSummary";
import ScheduleTimePage from "./Order/CarWash/Schedule/ScheduleTimePage";
import OrderDetail from "./Order/OrderDetail/OrderDetail";
import OrderHistory from "./Order/OrderHistory/OrderHistory";
import ReactGA from "react-ga";
import RouteChangeTracker from "./Shared/RouteChangeTracker";
import { Mixpanel } from "./utils/mixpanel"; // leave it here to allow initialization

const TRACKING_ID = "UA-200344452-2";
ReactGA.initialize(TRACKING_ID);

const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <RouteChangeTracker />
    <Switch>
      <Route exact path={"/"} component={Home} />
      <Route exact path={"/home"} component={Home} />
      <Route exact path={"/car-wash"} component={CarWashOrderMainPage} />
      <Route exact path={"/scheduling"} component={ScheduleTimePage} />
      <Route exact path={"/car-wash/summary"} component={CarWashOrderSummary} />
      <Route exact path={"/orders"} component={OrderHistory} />
      <Route exact path={"/order-details"} component={OrderDetail} />
      <Route exact path={"/delete"} component={DeleteAccount} />
      <Route path="" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
