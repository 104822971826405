import dummyData from "../dummyData.json";

const BASE_URL = process.env.REACT_APP_SPONGES_BACKEND_API;

function getAuthHeaders() {
  const token = localStorage.getItem("token");
  return {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
}

export async function sendVerificationCode(phoneNumber) {
  try {
    return await fetch(BASE_URL + "sendVerificationCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ phoneNumber }),
    });
  } catch (e) {
    return undefined;
  }
}

export async function singUp({ firstName, lastName, email, phoneNumber }) {
  return fetch(BASE_URL + "signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      phoneNumber,
      device: "website",
    }),
  });
}

export async function sendSms(destinationNumber, sms) {
  try {
    return await fetch(BASE_URL + "user/sms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ destinationNumber, sms }),
    });
  } catch (e) {
    return undefined;
  }
}

export async function getCarWashInfo({ lat, lng, address, serviceType }) {
  return fetch(BASE_URL + "location/car-wash-data", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ lat, lng, address, serviceType }),
  });
}

export async function getCarWashPackages({ lat, lng }) {
  return fetch(BASE_URL + "location/car-wash-packages", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ lat, lng }),
  });
}

export async function scheduleOrder(address, geoLocation, bodyCar, requestedTime, extraServices) {
  return fetch(BASE_URL + "order/schedule/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...getAuthHeaders(),
    },
    body: JSON.stringify({
      category: "Residential",
      location: address,
      geoLocation,
      bodyCar,
      requestedTime,
      services: extraServices,
    }),
  });
}

export async function createOrder({
  address,
  geoLocation,
  bodyCar,
  requestedTime,
  carWasherNote,
  extraServices,
  cost,
}) {
  return fetch(BASE_URL + "order/create/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...getAuthHeaders(),
    },
    body: JSON.stringify({
      category: "Residential",
      location: address,
      geoLocation,
      bodyCar,
      dateTime: requestedTime,
      cost,
      note: carWasherNote,
      services: extraServices,
    }),
  });
}

export async function getOrderHistory() {
  return fetch(BASE_URL + "order", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...getAuthHeaders(),
    },
  });
}

export async function fetchData(url, method = "POST", body = null, appJson = true) {
  if (url === "services") {
    return { status: "success", data: dummyData.services };
  }

  if (url === "order/hours") {
    return { status: "success", data: dummyData.order.hours };
  }

  if (url === "order/brands") {
    return { status: "success", data: dummyData.order.brands };
  }

  if (url === "order/carcases") {
    return { status: "success", data: dummyData.order.carcaseTypes };
  }

  if (url === "authorization/phone") {
    return { status: "success" };
    //return { status: 'error', message: 'Something went wrong' };
  }

  if (url === "authorization/digits") {
    return { status: "success", data: { registered: false } };
    // return { status: 'error', message: 'Wrong 4-digit code' };
    // return {
    //   status: 'success',
    //   data: {
    //     registered: true,
    //     name: 'John',
    //     lastName: 'Doe',
    //     email: 'johndoe@nomail.com',
    //     phone: '555555555',
    //		 token: '588858585888';
    //   },
    // };
  }

  if (url === "authorization/register") {
    return {
      status: "success",
      data: {
        name: "John",
        lastName: "Doe",
        email: "jonhdoe@nomail.com",
        phone: "5555555555",
        token: "588858585888",
      },
    };
    //return { status: 'error', message: 'Wrong registration request' };
  }
}
